.footer {
    margin-top: auto;
}

.footer span {
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    word-spacing: 3px;
    font-size: small;
    overflow: hidden;
    padding-bottom: 30px;
    padding-top: 30px;
    // background: #c89c3d;
    background: #000000;
    align-items: center;
    color: white;
}

.footer-link {
    color: rgb(242, 140, 40);
    font-weight: 600 !important;
}
.footer-link:hover {
    color: #ffffff;
}

.terms-and-conditions {
    margin-top: auto;
    // background: linear-gradient(to left, #323232, #000000)
    background: #000000;
    padding-top: 30px;
    padding-bottom: 30px;

    span {
        padding-bottom: 10px;
    }
    span a {
        color: white;
    }
    span a:hover {
        color: #d9ba79;
    }
}

.social-media-links {
    a {
        font-size: 18px;
        display: inline-block;
        // background: rgb(242, 140, 40);
        color: #ffffff !important;
        line-height: 1;
        padding: 8px 0;
        margin-right: 4px;
        border-radius: 50%;
        text-align: center;
        width: 36px;
        height: 36px;
        transition: 0.3s;
    }
}

@media (min-width: 769px) and (max-width: 1600px) {
    .terms-and-conditions span {
        font-size: medium;
    }
}
