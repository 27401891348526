/* @font-face {
    font-family: "Dosis";
    font-style: normal;
    font-weight: 500;
    src: local("Pacifico Regular"), local("Pacifico-Regular"),
        url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2)
            format("woff2");
    font-display: swap;
} */

* {
    margin: 0;
    padding: 0;
}

html {
    max-width: 100%;
    overflow-x: hidden;
    font-family: "Dosis", serif;
}

body {
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
    /* sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
