// .logo-container {
//     height: 100px;
//     width: 100px;
//     // padding: 25px;
//     background-image: url(../../assets/vector.svg);
//     background-repeat: no-repeat;
//   }

// .header-menu {
//   margin-bottom: 1.25rem;
// }

.hidden {
    display: none;
}

//   // li:not(:first-child):after {
//   //   position: absolute;
//   //   content: "";
//   //   width: 100%;
//   //   height: 3px;
//   //   top: 100%;
//   //   left: 0;
//   //   background: #ff7000;
//   //   transition: transform 0.5s;
//   //   transform: scaleX(0);
//   //   transform-origin: left;
//   // }

//   // li:not(:first-child):hover:after {
//   //   transform: scaleX(1);
//   // }

// New Code

.header-div {
    background: #f2f2f2;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: 1000;
    // margin-bottom: 30px;
}

ul {
    padding-left: 0;
    padding-bottom: 0;
}
nav {
    // background: #1b1b1b;
    // background: #a47f2e;
    background: #000000;
}

nav .logo {
    float: left;
    color: white;
    font-size: 27px;
    font-weight: 600;
    line-height: 70px;
    padding-left: 60px;
}
nav:after {
    content: "";
    clear: both;
    display: table;
}

nav ul {
    float: right;
    list-style: none;
    margin-right: 40px;
    position: relative;
    top: 10px;
}

nav ul li {
    float: left;
    display: inline-block;
    // background: #1b1b1b;
    // background: #a47f2e;
    background: #000000;
    margin: 0 5px;
    margin-bottom: -16px;
}

nav ul li a {
    color: white;
    text-decoration: none;
    line-height: 70px;
    font-size: 18px;
    padding: 8px 15px;
    text-transform: uppercase;
}

nav ul li a:hover {
    // box-shadow: 0 0 5px #33ffff, 0 0 5px #66ffff;
    // color: cyan;
    box-shadow: 0 0 5px rgb(242, 140, 40), 0 0 5px rgb(255, 192, 0);
    color: rgb(242, 140, 40);
}
nav ul ul {
    position: absolute;
    top: 90px;
    // border-top: 3px solid cyan;
    border-top: 3px solid rgb(242, 140, 40);
    opacity: 0;
    visibility: hidden;
    transition: top 0.3s;
}

nav ul ul ul {
    border-top: none;
}

nav ul li:hover > ul {
    top: 70px;
    opacity: 1;
    visibility: visible;
    width: max-content;
}

.level-2 {
    left: -28px;
}
nav ul ul li {
    position: relative;
    margin: 0px;
    width: -webkit-fill-available;
    float: none;
    display: list-item;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

nav ul ul li a {
    line-height: 50px;
}

nav ul ul li a:hover {
    // color: cyan;
    color: rgb(242, 140, 40);
    box-shadow: none;
}

nav ul ul ul li {
    position: relative;
    top: -70px;
    left: 150px;
}

.fa-plus {
    margin-left: 40px;
    font-size: 15px;
}

.icon,
#btn {
    display: none;
}

.cart-list > div {
    margin-top: 10px;
}

.cart-list > i {
    margin-top: 27px;
    cursor: pointer;
}

.user-name-display {
    color: white;
    text-align: center;
}

@media all and (max-width: 968px) {
    .menu {
        margin-bottom: 30px;
    }

    nav ul {
        margin-right: 0px;
        float: left;
    }
    nav .logo {
        padding-left: 30px;
        width: 100%;
    }

    nav ul li,
    nav ul ul li {
        display: block;
        width: 100%;
    }
    nav ul li i {
        padding-left: 12px;
    }

    nav ul li i {
        padding-left: 12px;
    }

    .divider-height {
        height: 0px;
    }

    .mrg-btm-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .menu {
        display: none;
    }

    nav ul ul {
        top: 70px;
        position: static;
        border-top: none;
        float: none;
        display: none;
        opacity: 1;
        visibility: visible;
    }

    nav ul ul ul li {
        position: static;
    }

    nav ul ul li {
        border-bottom: 0px;
    }

    nav ul ul a {
        padding-left: 40px;
    }
    nav ul ul ul a {
        padding-left: 80px;
    }

    .show {
        display: block;
        color: white;
        text-transform: uppercase;
        font-size: 18px;
        padding: 0 25px;
        line-height: 70px;
        cursor: pointer;
    }

    .show:hover {
        color: rgb(242, 140, 40);
    }

    .icon {
        display: block;
        color: white;
        position: absolute;
        top: 5px;
        right: 40px;
        line-height: 70px;
        font-size: 25px;
        cursor: pointer;
    }

    nav ul li a:hover {
        box-shadow: none;
    }

    .show + a,
    .header-ul {
        display: none;
    }

    .more {
        padding-left: 40px;
    }

    [id^="btn"]:checked + ul {
        display: block;
    }
}
