.quotes-container {
    background-color: #000000;
    padding-top: 10px;

    .quotes-col {
        margin-left: auto;
        margin-right: auto;

        h5 {
            color: rgb(242, 140, 40);
        }

        p {
            color: #ffffff;
        }
    }
}
