.background-img {
    // background-image: url("../../assets/web_background/5.jpg");
    // background-repeat: "no-repeat";
    // background-size: "cover";
}

.upcoming-webinars {
    padding-top: 20px;
    span {
        background-color: rgb(242, 140, 40);
    }
}

.recorded-webinars {
    span a {
        color: white;
    }
    span a:hover {
        color: black;
    }
}

.onHover:hover {
    cursor: pointer;
}

// .onHover > span > a:Hover {
//     color: white;
// }

.client-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.client-image > img {
    flex-shrink: 0;
}

@media (min-width: 320px) and (max-width: 360px) {
}

@media (min-width: 361px) and (max-width: 400px) {
}

@media (min-width: 401px) and (max-width: 450px) {
}

@media (min-width: 451px) and (max-width: 500px) {
}

@media (min-width: 501px) and (max-width: 550px) {
}

@media (min-width: 551px) and (max-width: 599px) {
}

@media (min-width: 600px) and (max-width: 650px) {
}

@media (min-width: 651px) and (max-width: 700px) {
}

@media (min-width: 701px) and (max-width: 750px) {
}

@media (min-width: 751px) and (max-width: 800px) {
}
@media (min-width: 801px) and (max-width: 850px) {
}
@media (min-width: 851px) and (max-width: 900px) {
}
@media (min-width: 901px) and (max-width: 950px) {
}
@media (min-width: 951px) and (max-width: 1000px) {
}

@media (min-width: 1001px) and (max-width: 1050px) {
}

@media (min-width: 1051px) and (max-width: 1100px) {
}
@media (min-width: 1101px) and (max-width: 1150px) {
}

@media (min-width: 1151px) and (max-width: 1200px) {
}
@media (min-width: 1201px) and (max-width: 1250px) {
}

@media (min-width: 1251px) and (max-width: 1300px) {
}
@media (min-width: 1301px) and (max-width: 1350px) {
}

@media (min-width: 1351px) and (max-width: 1400px) {
}
