.product-description-page {
    // width: 850px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    // margin:  30px auto;

    /* Columns */
    .left-column {
        width: 40%;
        position: relative;
    }

    .right-column {
        width: 60%;
        // margin-top: 60px;
    }

    /* Left Column */
    .left-column .image-div img {
        width: 100%;
        // position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        // transition: all 0.3s ease;
    }

    .left-column img.active {
        opacity: 1;
        padding-right: 10px;
    }

    /* Product Description */
    .product-description {
        border-bottom: 1px solid #e1e8ee;
        margin-bottom: 20px;
    }
    .product-description span {
        font-size: 28px;
        // color: #358ED7;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-decoration: none;
    }
    .product-description h1 {
        font-weight: 300;
        font-size: 38px;
        color: rgb(242, 140, 40);
        letter-spacing: -2px;
        @media screen and (max-width: 800px) {
            display: unset;
            font-size: 30px;
            letter-spacing: 0.5px;
        }
    }
    .product-description p {
        font-size: 16px;
        font-weight: 400;
        color: rgb(242, 140, 40);
        line-height: 24px;
    }

    /* Product Price */
    .product-price {
        display: flex;
        padding-right: 10px;
        justify-content: space-between;
    }

    .product-price span {
        font-size: 26px;
        font-weight: 400;
        color: rgb(242, 140, 40);
        margin-right: 20px;
    }

    .cart-btn {
        display: inline-block;
        background-color: #7dc855;
        border-radius: 6px;
        font-size: 16px;
        color: #ffffff;
        text-decoration: none;
        padding: 12px 30px;
        transition: all 0.5s;
    }
    .cart-btn:hover {
        background-color: #64af3d;
    }

    /* Responsive */
    // @media (max-width: 940px) {
    //     .container {
    //     flex-direction: column;
    //     margin-top: 60px;
    //     }

    //     .left-column,
    //     .right-column {
    //     width: 100%;
    //     }

    //     .left-column img {
    //     width: 300px;
    //     right: 0;
    //     top: -65px;
    //     left: initial;
    //     }
    // }

    // @media (max-width: 535px) {
    //     .left-column img {
    //     width: 220px;
    //     top: -85px;
    //     }
    // }
}

.box-padding {
    padding: 2rem;
}
